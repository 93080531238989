$mobileNav--button-top: 15px !default;
$mobileNav--button-right: 15px !default;
$mobileNav--button-width: 30px !default;
$mobileNav--button-height: 30px !default;
$mobileNav--button-font-size: 30px !default;
$mobileNav--button-color: #fff !default;

$mobileNav--menu-width: 100% !default;
$mobileNav--container-width: 70% !default;
$mobileNav--container-box-shadow: 0 0 20px rgba(0, 0, 0, 1) !default;
$mobileNav--container-background: #fff !default;

$mobileNav--closeBt-background: $navbar-color !default;
$mobileNav--closeBt-color: $font-color-h2 !default;

$mobileNav--nav-background: $navbar-color !default;
$mobileNav--nav-text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.2) !default;
$mobileNav--nav-padding-left: 10px !default;
$mobileNav--nav-box-shadow: 0 1px 5px #000000 !default;
$mobileNav--nav-line-height: 20px !default;
$mobileNav--nav-font-size: 20px !default;
$mobileNav--nav-padding: 20px 0;


.mobilemenu {
  display: none;
}
.mobilemenu__button {
  display: none;
  position: absolute;
  top: $mobileNav--button-top;
  right: $mobileNav--button-right;
  width: $mobileNav--button-width;
  height: $mobileNav--button-height;
  font-size: $mobileNav--button-font-size;
  text-align: right;
  color: $mobileNav--button-color;
  i {
    vertical-align: top;
    top:0;
  }
}

@include mobile {
  .mobilemenu__button {
    display: block;
  }
  .mobilemenu {
    position: fixed;
    height: 100%;
    width: $mobileNav--menu-width;
    top: 0;
    left: auto;
    right: -$mobileNav--menu-width;
    display: block !important;
    bottom: 0;
    z-index: 99999;
    transition: right 0.3s ease-in-out 0s;
    overflow: auto;
    will-change: right;
    .mobilemenu__container {
      position: absolute;
      right: 0;
      top: 0;
      width: $mobileNav--container-width;
      height: 100%;
      box-shadow: $mobileNav--container-box-shadow;
      overflow: auto;
      background: $mobileNav--container-background;
    }
    .mobilemenu__button-close {
      font-size: 35px;
      line-height: 30px;
      background: $mobileNav--closeBt-background;
      position: absolute;
      right: 0;
      width: 60px;
      z-index: 10;
      height: 55px;

      i {
        float: right;
        right: 15px;
        top: 15px;
        z-index: 9;
        height: 29px;
        width: 29px;
        color: $mobileNav--closeBt-color;
      }
    }
    .navbar-nav {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 0;
      overflow: auto;
      padding-bottom: 0;
      float: none;
      background: $mobileNav--nav-background;
      li {
        float: none;
        width: 100%;
        &.main-menu-item {
          background: $mobileNav--nav-background;
          padding: 0;
        }
        &.sub-menu-item {

        }
        a {
          text-align: left;
        }
      }

      > li > a {
        text-shadow: $mobileNav--nav-text-shadow;
        padding-left: $mobileNav--nav-padding-left;
        box-shadow: $mobileNav--nav-box-shadow;
      }
      li a {
        line-height: $mobileNav--nav-line-height;
        padding: $mobileNav--nav-padding;
        font-size: $mobileNav--nav-font-size;
        color: $font-color-h1;
        white-space: normal;
      }
      > li.main-menu-item > a {
        padding-left: 10px;
      }
      .dropdown-menu, .dropdown-menu li {
        list-style: none;
        padding-left: 20px;
        a {
          text-align: left;
          font-size: 16px;
          font-weight: 600;
          padding: 10px 15px;
          //text-shadow: 1px 1px 0 #fff;
          color: $navbar-default-link-color;
        }
        &.active a {
          color: #000;
          background-color: transparent;
        }
      }
      .dropdown-menu {
        font-family: $font-family-base;
        padding: 10px 0 0;
        border-radius: 0;
        background: $submenu-bg-color;
        li {
          background: transparent;
        }
        display: block;
        opacity: 1;
        visibility: visible;
        z-index: 1;
      }
    }
    &.open {
      right: 0;
    }
  }
}
