$linkboxen--box-shadow: 0px 0px 20px #000 !default;
$linkboxen__name--background: rgba(255, 255, 255, 0.7) !default;
$linkboxen__imgdiv--border: 1px solid #ff5000 !default;
$linkboxen__a--color: #00ff00 !default;
$linkboxen__a--font-family: Arial !default;
$linkboxen__a--font-size: 25px !default;
$linkboxen__a--height: 45px !default;
$linkboxen__a--font-weight: 300 !default;

$linkboxen__a-hover--font-size: 35px !default;
$linkboxen__a-hover--height: 50% !default;

.linkboxen {
  margin: 0px;
  .linkbox {
    overflow: hidden;
    padding: 0px;

    @include media-breakpoint-down('md') {
      .linkbox__imgdiv {
        margin: 5px !important;
        .linkbox__name {
          font-size: 25px !important;
        }
      }
    }

    .linkbox__imgdiv {
      margin: 15px;
      position: relative;
      overflow: hidden;
      box-shadow: $linkboxen--box-shadow;
      border: $linkboxen__imgdiv--border;
      .optimizedpicture {
        padding-top: 100%;
        position: relative;

        picture {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .linkbox__name {
        position: absolute;
        bottom: 0px;
        text-align: center;
        height: $linkboxen__a--height;
        font-size: $linkboxen__a--font-size;
        font-weight: $linkboxen__a--font-weight;
        width: 100%;
        background: $linkboxen__name--background;
        transition: height 0.3s linear, font-size 0.3s linear;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }

    a {
      color: $linkboxen__a--color;
      font-family: $linkboxen__a--font-family;
    }

    a:hover {
      .linkbox__name {
        height: $linkboxen__a-hover--height;
        font-size: $linkboxen__a-hover--font-size;
      }
    }
  }
}