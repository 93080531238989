a.download-file {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #000;
  margin-bottom: 10px;
  img {
    width: 60px;
    height: 60px;
  }
  .download-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    .hline {
      margin: 0;
      border-bottom: none;
    }
    span {
      color: #e0e0e0;
    }
    strong.filename {
      color: #000;
    }
  }
}
