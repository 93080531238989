$rexshop__list--font-family: Arial !default;
$rexshop__list--color: #ff0000 !default;
$rexshop__list--font-size: 15px !default;
$rexshop__list--line-height: 50px !default;
$rexshop__list--font-weight: 300 !default;
$rexshop__list__a--color: #000 !default;
$rexshop__list__a--font-family: Arial !default;
$rexshop__list--background: #fff !default;
$rexshop__list__article__preis--color: #000 !default;
$rexshop__list__article__preis--font-weight: unset !default;

.rexshop__product {
  margin-bottom: 15px;
  margin-top: 15px;
}

.rexshop__list {
  box-shadow: 0 0 20px rgba(50,28,12,.1);
  background: $rexshop__list--background;
  padding-bottom: 60px;
  overflow: hidden;

  a {
    color: $rexshop__list__a--color;
    font-family: $rexshop__list__a--font-family;
  }

  a:hover {
    .rexshop__list__article--title {
      position: absolute;
      bottom: 0px;
      text-align: center;
      height: $linkboxen__a--height;
      font-size: $linkboxen__a--font-size;
      font-weight: $linkboxen__a--font-weight;
      width: 100%;
      background: $linkboxen__name--background;
      transition: height 0.3s linear, font-size 0.3s linear;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }

  .rexshop__list__article--content {
    padding: 15px;
  }

  .rexshop__list__article--preis {
    padding: 0px 15px 15px 15px;
    position: absolute;
    bottom: 0px;
    font-weight: bold;
    color: $rexshop__list__article__preis--color;
  }

  .rexshop__list__article--btn {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 15px;
  }

  .rexshop__list__article--btn {
    button:active,
    button:focus {
      box-shadow: 0px 0px 5px #ff0000;
    }
  }

  .optimizedpicture {
    padding-top: 74.86%;
    position: relative;

    picture {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }

    }
  }
}

.rexshop__list__article--title {
  font-family: $rexshop__list--font-family;
  color: $rexshop__list--color;
  font-size: $rexshop__list--font-size;
  font-weight: $rexshop__list--font-weight;
  line-height: $rexshop__list--line-height;
}

.warenkorb__product__quantity {
  width: 70px;
}

.warenkorb__product__einzelpreis {
  width: 120px;
  text-align: right;
}

.warenkorb__product__gesamtpreis {
  width: 120px;
  text-align: right;
}